// 
// authentication.scss
//

.authentication-bg {
  background-image: url("../images/dust.png");
}


// authentication home icon
.home-btn {
  position: absolute;
  top: 15px;
  right: 25px;
}


.account-card-box{
  background-color: $card-bg;
  padding: 7px;
  border-radius: 8px;
  
  .card{
    border: 4px solid $primary;
  }
}

// Pges


.account-btn {
  position: absolute;
  left: 0;
  right: 0;
}

.account-logo-box {
  padding: 10px;
  border-radius: 5px 5px 0 0;
  background: $dark;
}

.checkmark {
  width: 120px;
  margin: 0 auto;
  padding: 20px 0;
}

.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
  animation: dash 2s ease-in-out;
  -webkit-animation: dash 2s ease-in-out;
}

.spin {
  animation: spin 2s;
  -webkit-animation: spin 2s;
  transform-origin: 50% 50%;
  -webkit-transform-origin: 50% 50%;
}

@-webkit-keyframes dash {
 0% {
   stroke-dashoffset: 1000;
 }
 100% {
   stroke-dashoffset: 0;
 }
}

@keyframes dash {
 0% {
   stroke-dashoffset: 1000;
 }
 100% {
   stroke-dashoffset: 0;
 }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes text {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

  @keyframes text {
  0% {
    opacity: 0; }
  100% {
    opacity: 1;
  }
}
