// 
// faq.scss
//

.question-q-box{
    height: 30px;
    width: 30px;
    color: $white;
    text-align: center;
    border-radius: 3px;
    float: left;
    font-weight: $font-weight-bold;
    line-height: 30px;
    background-color: $primary;
}

.question{
    margin-top: 0;
    margin-left: 50px;
    font-weight: 400;
    font-size: 16px;
}

.answer {
    margin-left: 50px;
    color: $gray-600;
}