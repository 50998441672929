// 
// components-demo.scss
//

// Demo Only
.button-list {
    margin-left: -8px;
    margin-bottom: -12px;
    
    .btn {
        margin-bottom: 12px;
        margin-left: 8px;
    }
}


// video
.embed-responsive{
  &:before{
    padding-top: 56%;
  }
}

// Icon demo ( Demo only )

.icon-list-demo{
    div {
        cursor: pointer;
        line-height: 45px;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
        overflow: hidden;
        p {
            margin-bottom: 0;
            line-height: inherit;
          }
      }
      i {
        text-align: center;
        vertical-align: middle;
        font-size: 24px;
        border: 1px solid lighten($gray-300, 4%);
        width: 50px;
        height: 50px;
        line-height: 50px;
        margin-right: 12px;
        border-radius: 3px;
        display: inline-block;
        transition: all 0.2s;
      }
      .col-lg-4 {
        border-radius: 3px;
        -moz-border-radius: 3px;
        background-clip: padding-box;
        margin-bottom: 10px;
        &:hover{
            i {
                color: $primary;
              }
        }
      }
}



// Grid

.grid-structure {
    .grid-container {
        background-color: lighten($gray-200, 2%);
        margin-top: 10px;
        font-size: .8rem;
        font-weight: $font-weight-medium;
        padding: 10px 20px;
    }
}

/* Icon colored demo */
.icon-colored {
    height: 48px;
    width: 48px;
    margin: 1.4rem;
  }
  
  .icon-colored-lg {
    height: 64px;
    width: 64px;
    margin: 2.2rem;
  }

  /* Search box */
.search-box {
    position: relative;
    .btn-search {
        position: absolute;
        top: 2px;
        right: 3px;
        background-color: transparent !important;
        border: none !important;
        font-size: 15px;
        box-shadow: none !important;
        outline: none !important;
        color: $gray-500;
      }
      .form-control {
        border: 2px solid rgba($gray-500, 0.5);
        height: 38px;
        border-radius: 30px;
        padding: 8px 45px 8px 20px;
        &:focus {
                border-width: 2px !important;
              }
      }
  }
  

  // Demos button
.demos-show-btn {
  position: fixed;
  top: 50%;
  right: 0;
  writing-mode: vertical-rl;
  font-weight: 600;
  background-color: $danger;
  color: $white !important;
  line-height: 36px;
  padding: 15px 3px;
  border-radius: 6px 0 0 6px;
  transform: translateY(-50%);
  text-transform: uppercase;
}

@media (max-width: 600px) {
  .demos-show-btn {
      display: none;
  }
}
 