
//
// vector-maps.scss
//


.jvectormap-zoomin,
.jvectormap-zoomout {
  line-height: 6px;
}



.jvectormap-label {
  border: none;
  background: $dark;
  color: $white;
  font-family: $font-family-secondary;
  font-size: $font-size-base;
  padding: 5px 8px;
}