// 
// progress.scss
//

// Custom height

.progress {
  box-shadow: none;
  margin-bottom: 18px;
  overflow: hidden;
  height: 0.7rem;
}

// Progress height small
.progress-sm {
  height: 5px;
}


// Progress height medium
.progress-md {
  height: 8px;
}


// Progress height large
.progress-lg {
  height: 12px;
}

// Progress height extra large
.progress-xl {
  height: 15px;
}

// Progress height dubble extra large
.progress-xxl {
  height: 25px;
}


/* Progressbar Vertical */
.progress-vertical {
  min-height: 250px;
  height: 250px;
  width: 10px;
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  margin-right: 20px;

  .progress-bar {
    width: 100%;
  }

  &.progress-xl {
    width: 15px;
  }

  &.progress-lg {
    width: 12px;
  }

  &.progress-md {
    width: 8px;
  }

  &.progress-sm {
    width: 5px;
  }
}

.progress-vertical-bottom {
  min-height: 250px;
  height: 250px;
  position: relative;
  width: 10px;
  display: inline-block;
  margin-bottom: 0;
  margin-right: 20px;

  .progress-bar {
    width: 100%;
    bottom: 0;
    position: absolute;
  }

  &.progress-xl {
    width: 15px;
  }

  &.progress-lg {
    width: 12px;
  }

  &.progress-md {
    width: 8px;
  }

  &.progress-sm {
    width: 5px;
  }
}

// Progress with percentage

.progress-w-percent {
  min-height: 20px;
  margin-bottom: 20px;

  .progress {
    width: 80%;
    float: left;
    margin-top: 8px;
  }

  .progress-value {
    width: 20%;
    float: right;
    text-align: right;
    line-height: 20px;
  }
}