

/* Tooltpster */


.demo-popover .popover {
    position: relative;
    display: block;
    float: left;
    width: 260px;
    margin: 20px;
    z-index: 9;
  }

.popover {
    font-family: inherit;
    border: none;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -moz-border-radius: 3px;
    background-clip: padding-box;
    box-shadow: $box-shadow;
  
    .popover-title {
      background-color: transparent;
      color: $primary;
      padding: 12px 15px;
      font-size: 15px;
      border-bottom: 1px solid $light;
    }
  
    .popover-content {
      padding: 10px 14px;
    }
    .popover-header{
      margin-top: 0px;
    }
  }
  


.tooltipster-sidetip {
    .tooltipster-box {
        background-color: $primary;
        border: 2px solid $primary;
    }

    &.tooltipster-top{
        .tooltipster-arrow-border, .tooltipster-arrow-background{
            border-top-color: $primary;
        }
    }

    &.tooltipster-bottom{
        .tooltipster-arrow-border, .tooltipster-arrow-background{
            border-bottom-color: $primary;
        }
    }

    &.tooltipster-left{
        .tooltipster-arrow-border, .tooltipster-arrow-background{
            border-left-color: $primary;
        }
    }

    &.tooltipster-right{
        .tooltipster-arrow-border, .tooltipster-arrow-background{
            border-right-color: $primary;
        }
    }
}
