//
// clockpicker.scss
//

.clockpicker-popover {
    .popover-title{
        font-size: 16px;
        font-weight: $font-weight-semibold;
        background-color: lighten($gray-100, 4%);
    }

    .popover-content{
        background-color: lighten($gray-300, 4%);
    }

    .clockpicker-plate{
        background-color: lighten($gray-100, 4%);
        border-color: $gray-200;
    }

    .clockpicker-tick{
        color: $gray-500;
    }
    
    .btn-default {
        background-color: $primary;
        color: $white;
    }
}